// eslint-disable-next-line
const ENV = process.env.APP_ENV || 'production';

const getBaseAppLink = () => {
  if (ENV === 'production') {
    return 'https://kellercovered.com/app';
  }

  if (ENV === 'dev') {
    return 'https://new-marketing-dev.kellercovered.io/app';
  }
  return 'https://new-marketing-stg.kellercovered.io/app';
};

const getBaseEoLink = () => {
  if (ENV === 'production') {
    return 'https://kellercovered.com/eo';
  }

  if (ENV === 'dev') {
    return 'https://new-eo-dev.kellercovered.io';
  }
  return 'https://new-eo-stg.kellercovered.io';
};

const getBaseLink = () => {
  if (ENV === 'production') {
    return 'https://kellercovered.com';
  }

  if (ENV === 'dev') {
    return 'https://new-marketing-dev.kellercovered.io';
  }
  return 'https://new-marketing-stg.kellercovered.io';
};

const getBaseAPI = () => {
  if (ENV === 'production') {
    return 'https://svc.kellercovered.com';
  }
  if (ENV === 'dev') {
    return 'https://svc2.kellercovered.io';
  }
  return 'https://svc1.kellercovered.io';
};

const getNewBaseAPI = () => {
  if (ENV === 'production') {
    return 'https://api2.kellercovered.com';
  }
  if (ENV === 'dev') {
    return 'https://api2-dev.kellercovered.io';
  }
  return 'https://api2-staging.kellercovered.io';
};

const getReCaptchaSiteKey = () => {
  if (ENV === 'production') {
    return '6LexUsAUAAAAAEA6op2VON2jclFOdfMQp6bWXcAl';
  }
  /**
   * test key https://developers.google.com/recaptcha/docs/faq#what-happens-to-recaptcha-v1
   * to test on dev use dev key from 1Pass
   */
  return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
};

const getGaId = () => 'UA-178759703-1';

const getNewApi = () => {
  if (ENV === 'dev') {
    return 'https://api2-dev.kellercovered.io';
  }

  if (ENV === 'production') {
    return 'https://api2.kellercovered.com';
  }

  return 'https://api2-staging.kellercovered.io';
};

const getSentryDSN = () => {
  if (ENV === 'production') {
    return 'https://3e7844a34c9643e297d6b3763a88ecd5@o464555.ingest.sentry.io/5566281';
  }
  if (ENV === 'demo') {
    return 'https://36595fdc857f4d15abaf5254e721df15@o464555.ingest.sentry.io/5566280';
  }
  return null;
};

const RENTERS_SOURCE_IDENTIFIER = '7819591269';
const ORGANIC_SOURCE_IDENTIFIER = '2113525091';

module.exports = {
  ENV,
  BASE_LINK: getBaseLink(),
  BASE_APP_LINK: getBaseAppLink(),
  BASE_EO_LINK: getBaseEoLink(),
  BASE_API: getBaseAPI(),
  NEW_BASE_API: getNewBaseAPI(),
  BASE_RECAPTCHA_SITE_KEY: getReCaptchaSiteKey(),
  GA_ID: getGaId(),
  NEW_API: getNewApi(),
  RENTERS_SOURCE_IDENTIFIER,
  ORGANIC_SOURCE_IDENTIFIER,
  SENTRY_DSN: getSentryDSN(),
};
